import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  limit: 0,
  list: []
};

const cellphoneCarrierReducer = ( state, action: DispatchAction ) => {

  switch (action.type) {
    case Actions.GET_CELLPHONE_CARRIER_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.GET_CELLPHONE_CARRIER_LIST_SUCCESS: {
      return {
        ...state,
        total: action.payload.data.length,
        list: action.payload.data,
        isLoading: false
      }
    }
    case Actions.GET_CELLPHONE_CARRIER_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    default: {
      if(!state)
        return initialState;
      return state;
    }
  }

 //return initialState;
};

export default cellphoneCarrierReducer;
