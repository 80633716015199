import { combineReducers } from "redux";
import orderReducer from "./order.reducer";
import cellphoneCarrierReducer from "./cellphonecarrier.reducer";
import cognitouserReducer from "./cognitouser.reducer";
import courtReducer from "./court.reducer";

const reducers = combineReducers({
  cognitouserReducer,
  orderReducer,
  cellphoneCarrierReducer,
  courtReducer
});

export default reducers;