import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Auth from "./providers/AuthorizedProvider";
import { roleConfig } from './config/roleconfig';

const Loader = (Component) => (props) =>
  (
    
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Overview = Loader(lazy(() => import('src/content/overview')));

// Status
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

// Login Page
const LoginPage = Loader(
  lazy(() => import('src/content/signin/LoginNew'))
);
// Signin Page
const SigninPage = Loader(
  lazy(() => import('src/content/signin/Login'))
);
// Email Verify Page
const VerifyEmailPage = Loader(
  lazy(() => import('src/content/signin/VerifyEmail'))
);
// Forgot Password Page
const ForgotPasswordPage = Loader(
  lazy(() => import('src/content/signin/ForgotPassword'))
);

// manageorderdocuments
const ManagementOrderdocuments = Loader(
  lazy(() => import('src/content/manageorderdocuments'))
);

// Others
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="manageorderdocs" replace />
      },
      {
        path: 'login',
        element: <LoginPage />
      },
      {
        path: 'signin',
        element: <SigninPage />
      },
      {
        path: 'verify',
        element: <VerifyEmailPage />
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />
      },
      {
        path: 'status',

        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="profile" replace />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="settings" replace />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: 'manageorderdocs',
    element: <Auth allowedRoles={[
      roleConfig.portal.witness
    ]} />,
    children: [
      {
        path: '',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <ManagementOrderdocuments />
          }
        ]
      }
    ]
  }
];

export default routes;
