import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  limit: 0,
  list: []
};

const courtReducer = ( state, action: DispatchAction ) => {

  switch (action.type) {
    case Actions.GET_COURT_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.GET_COURT_LIST_SUCCESS: {
      return {
        ...state,
        total: action.payload.data.length,
        list: action.payload.data,
        isLoading: false
      };
    }
    case Actions.GET_COURT_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error
      };
    }
    default: {
      if(!state)
        return initialState;
      return state;
    }
  }

};

export default courtReducer;
