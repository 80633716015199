const adminRoleConfig = {
    superadministrator: "superadministrator",
    administrator:"administrator"
};

const userRoleConfig = {
    lawyer: "lawyer",
};

const portalRoleConfig = {
    witness: "witness"
}

export const roleConfig = {
    admin: adminRoleConfig,
    user: userRoleConfig,
    portal: portalRoleConfig
}