import * as Actions from "../../redux/constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  isOnAuthorizing: false,
  isVerifying: false,
  isConfirmEmail: false,
  user: null,
  error: null,
};
// eslint-disable-next-line @typescript-eslint/default-param-last
const cognitouserReducer = (state = initialState, action: DispatchAction) => {
  switch (action.type) {
  // Register reducer
  case Actions.COGNITOUSER_CHCKIFEMAILVERIFIED_REQUEST: {
    return {
      ...state,
      isVerifying: true,
      isLoading: true
    };
  }
  case Actions.COGNITOUSER_CHCKIFEMAILVERIFIED_SUCCESS: {
    return {
      ...state,
      user: action.payload,
      isVerifying: false,
      isLoading: false
    };
  }
  case Actions.COGNITOUSER_CHCKIFEMAILVERIFIED_FAILURE: {
    return {
      ...state,
      isVerifying: false,
      isLoading: false,
      error: action.error
    };
  }
  // Email verification reducer
  case Actions.COGNITOUSER_VERIFY_EMAIL_REQUEST: {
    return {
      ...state,
      isVerifying: true,
      isLoading: true
    };
  }
  case Actions.COGNITOUSER_VERIFY_EMAIL_SUCCESS: {

    return {
      ...state,
      isVerifying: false,
      isLoading: false,
      user: {
        ...(state.user || {}),
        email_verified: true
      }
    };
  }
  case Actions.COGNITOUSER_VERIFY_EMAIL_FAILURE: {
    return {
      ...state,
      isVerifying: false,
      isLoading: false,
      error: action.error
    };
  }
  case Actions.COGNITOUSER_GETCONFIRMATIONSTATUS_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.COGNITOUSER_GETCONFIRMATIONSTATUS_SUCCESS: {
    return {
      ...state,
      user: {
        ...(state.user || {}),
        UserStatus: action.payload.result
      },
      isLoading: false
    };
  }
  case Actions.COGNITOUSER_GETCONFIRMATIONSTATUS_FAILURE: {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }
  case Actions.COGNITOUSER_REGISTER_REQUEST: {
    return {
      ...state,
      isLoading: true,
    };
  }
  case Actions.COGNITOUSER_REGISTER_SUCCESS: {
    return { 
      ...state,
      isLoading: false,
      user: {
        ...(state.user || {}),
        email_verified: true
      }
    };
  }
  case Actions.COGNITOUSER_REGISTER_FAILURE: {
    return { 
      ...state,
      isLoading: false,
    };
  }
  case Actions.COGNITOUSER_UPDATEINFO_REQUEST: {
    return {
      ...state,
      isLoading: true,
    };
  }
  case Actions.COGNITOUSER_UPDATEINFO_SUCCESS: {
    return { 
      ...state,
      isLoading: false,
      user: {
        ...(state.user || {}),
        ...action.payload.data
      }
    };
  }
  case Actions.COGNITOUSER_UPDATEINFO_FAILURE: {
    return { 
      ...state,
      isLoading: false,
    };
  }

  // Login reducer
  case Actions.COGNITOUSER_LOGIN_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.COGNITOUSER_LOGIN_SUCCESS: {
    return {
      ...state,
      isLoading: false
    };
  }
  case Actions.COGNITOUSER_LOGIN_FAILURE: {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }
  // Login with token reducer
  case Actions.COGNITOUSER_LOGIN_WITH_TOKEN_REQUEST: {
    return {
      ...state,
      isOnAuthorizing: true
    };
  }
  case Actions.COGNITOUSER_LOGIN_WITH_TOKEN_SUCCESS: {
    return {
      ...state,
      isOnAuthorizing: false,
      user: action.payload
    };
  }
  case Actions.COGNITOUSER_LOGIN_WITH_TOKEN_FAILURE: {
    return {
      ...state,
      isOnAuthorizing: false,
      error: action.error
    };
  }

  case Actions.COGNITOUSER_LOGOUT: {
    return initialState;
  }
  default: {
    if(state)
      return state;
    return initialState;
  }
  }
};

export default cognitouserReducer;
